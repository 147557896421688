import React, { useRef, useEffect, useState } from "react";
import { GiArcheryTarget } from "react-icons/gi";
import ClientsFeedback from "./ClientsFeedback";
import RecentProjects from "./RecentProjects";
import Hero1 from "./Hero1";
import "../css/flaticon.min.css";
import "../Main.css";
import SideBar from "./SideBar";
import { sideBarToggle } from "./utils";
import HomeMenu from "./HomeMenu";
import MobileMenuHome from "./MobileMenuHome";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Box, Button, Modal } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const CoreFeatures = () => {
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({
    name: "",
    mail: "",
    phonenumber: "",
    subject: "",
    message: "",
  });
  const [isloader, setIsloader] = useState(false);
  const handleContact = async (e: any) => {
    e.preventDefault();
    try {
      setIsloader(true);
      await axios.post("https://tth-website-api.onrender.com/ContactUs", {
        name: data.name,
        userEmail: data.mail,
        userPhonenumber: data.phonenumber,
        subject: data.subject,
        message: data.message,
      });
      setData({
        name: "",
        mail: "",
        phonenumber: "",
        subject: "",
        message: "",
      });
      setOpen(true);
      setIsloader(false);
    } catch (err: any) {
      console.log(err.message);
      setIsloader(false);
      alert("Got internal error");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Determine the scroll threshold where the header should become fixed
      const scrollThreshold = 100;

      // Update the state based on the scroll position
      setScrolled(scrollTop > scrollThreshold);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const valueRef = useRef<HTMLDivElement | null>(null);
  const projectRef = useRef<HTMLDivElement | null>(null);
  const teamRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          animateValue(valueRef, 0, 2365, 1000);
          animateValue(projectRef, 0, 5234, 1000);
          animateValue(teamRef, 0, 8532, 1000);
        }
      });
    }, options);

    if (valueRef.current) observer.observe(valueRef.current);
    if (projectRef.current) observer.observe(projectRef.current);
    if (teamRef.current) observer.observe(teamRef.current);
  }, []);

  function animateValue(obj: any, start: any, end: any, duration: any) {
    let startTimestamp: any = null;

    const step = (timestamp: any) => {
      if (!startTimestamp) startTimestamp = timestamp;

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const currentValue = Math.floor(progress * (end - start) + start);
      obj.current.innerText = currentValue.toString() + "+";

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  const { t } = useTranslation();

  return (
    <>
      <header
        className={`main-header header-two ${scrolled ? "fixed-header" : ""}`}
      >
        {/*Header-Upper*/}
        <div className="header-upper1">
          <div className="container clearfix">
            <div className="header-inner rel d-flex align-items-center justify-content-between">
              <div className="logo-outer">
                <div className="logo">
                  <Link to="/">
                    <a>
                      <img
                        src="/assets/tth_logo.png"
                        alt="Logo"
                        title="Logo"
                        style={{ height: "45px", width: "auto" }}
                      />
                    </a>
                  </Link>
                </div>
              </div>
              <div className="nav-outer clearfix mx-0">
                <MobileMenuHome logo={"/images/logos/logo-two.png"} />
                {/* Main Menu */}
                <nav className="main-menu d-none d-lg-block navbar-expand-lg">
                  <div className="navbar-header">
                    <div className="mobile-logo my-15">
                      <a href="/">
                        <a>
                          <img
                            src="/images/logos/logo-two.png"
                            alt="Logo"
                            title="Logo"
                          />
                        </a>
                      </a>
                    </div>
                    {/* Toggle Button */}
                    <button
                      type="button"
                      className="navbar-toggle"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse"
                    >
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix">
                    <HomeMenu />
                  </div>
                </nav>
                {/* Main Menu End*/}
              </div>
              {/* Menu Button */}
              <div className="menu-btns">
                <div className="menu-sidebar d-none d-lg-block">
                  <button onClick={() => sideBarToggle()}>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                  <SideBar />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Header Upper*/}
      </header>
      <Hero1 />
      <section
        className="feature-area-five bgc-lighter pt-100 pb-70"
        style={{ backgroundColor: "#f7f9fd", overflow: "hidden" }}
      >
        <div className="container">
          <div className="section-title text-center mb-60 wow fadeInUp delay-0-2s">
            <span className="sub-title mb-10">{t("coreFeatureH")}</span>
            <h2>{t("coreFeatureSub")}</h2>
          </div>
          <div className="row row-cols-xl-6 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 justify-content-center">
            <div className="col wow fadeInUp delay-0-2s">
              <div className="feature-item-five">
                <i className="flaticon-responsive" />
                <h5>
                  <a href="">
                    <a>{t("coreCards1")}</a>
                  </a>
                </h5>
              </div>
            </div>
            <div className="col wow fadeInUp delay-0-3s">
              <div className="feature-item-five">
                <i className="flaticon-feature" />
                <h5>
                  <a href="">
                    <a>{t("coreCards2")}</a>
                  </a>
                </h5>
              </div>
            </div>
            <div className="col wow fadeInUp delay-0-4s">
              <div className="feature-item-five">
                <i className="flaticon-aim" />
                <h5>
                  <a href="">
                    <a>{t("coreCards3")}</a>
                  </a>
                </h5>
              </div>
            </div>
            <div className="col wow fadeInUp delay-0-5s">
              <div className="feature-item-five">
                <i className="flaticon-seo" />
                <h5>
                  <a href="">
                    <a>{t("coreCards4")}</a>
                  </a>
                </h5>
              </div>
            </div>
            <div className="col wow fadeInUp delay-0-6s">
              <div className="feature-item-five">
                <i className="flaticon-search-location" />
                <h5>
                  <a href="">
                    <a>{t("coreCards5")}</a>
                  </a>
                </h5>
              </div>
            </div>
            <div className="col wow fadeInUp delay-0-7s">
              <div className="feature-item-five">
                <i className="flaticon-settings" />
                <h5>
                  <a href="">
                    <a>{t("coreCards6")}</a>
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*Offical partner start*/}
      <section
        className="about-area-one pt-70 pb-10 rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
        <div className="section-title text-center mb-60 wow fadeInUp delay-0-2s">
            <span className="sub-title mb-10">Our Trusted Partner</span>
            <h2>Building a future of technology through strategic partnerships</h2>
            <div className="row pt-3">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-center"><div>
                <h5 className="partner-head">"we believe that collaboration drives innovation and excellence. Our partnerships reflect our commitment to delivering cutting-edge technology solutions by teaming up with industry leaders who share our vision."</h5>

                </div></div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center align-items-center"><div>
               <img src="/assets/odoo.png" style={{width:"400px"}} alt="odoo"  className="img-fluid" loading="lazy"/>
                </div></div>
            </div>
          </div>
        </div>
      </section>
      {/*Offical partner end*/}
      {/* About Us Area start */}
      <section
        className="about-area-one pt-130 pb-125 rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5">
              <div className="about-image-one bg-squire-shape rmb-85 wow fadeInUp delay-0-2s">
                <img src="/assets/lady.webp" alt="About us" loading="lazy" />
                <img
                  className="image-left"
                  src="/images/shapes/image-left.png"
                  alt="shape"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="about-content-one wow fadeInUp delay-0-4s">
                <div className="section-title mb-45">
                  <span className="sub-title mb-15">{t("AllaboutH")}</span>
                  <h2>{t("AllaboutSub")}</h2>
                </div>
                <ul className="list-style-one">
                  <li>
                    <div className="content">
                      <h4>{t("missionH")}</h4>
                      <p>{t("missionPara")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h4>{t("visionH")}</h4>
                      <p>{t("visionPara")}</p>
                    </div>
                  </li>
                  <li>
                    <div className="content">
                      <h4>{t("philosophyH")}</h4>
                      <p>{t("philosophyPara")}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* About Us Area end */}
      {/* Design Featured Start */}
      <section className="design-feature-area overflow-hidden pt-130 pb-100 text-white bgc-black-with-lighting rel z-1">
        <div className="container">
          <div className="section-title text-center mb-60 wow fadeInUp delay-0-2s">
            <span className="sub-title mb-10">{t("coredesignH")}</span>
            <h2>{t("corededignSub")}</h2>
          </div>
          <div className="row no-gap align-items-center">
            <div className="col-lg-3">
              <div className="feature-left">
                <div className="row">
                  <div className="col-lg-12 col-sm-6">
                    <div className="service-item style-three wow fadeInRight delay-0-2s">
                      <div className="icon">
                        <i className="flaticon-web-programming" />
                      </div>
                      <div className="content">
                        <h4>
                          <a>{t("core1")}</a>
                        </h4>
                        <Link to="/web-development">
                          <a className="more-btn">
                            <i className="fal fa-long-arrow-right" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-6">
                    <div className="service-item style-three wow fadeInRight delay-0-3s">
                      <div className="icon">
                        <i className="flaticon-smartphone" />
                      </div>
                      <div className="content">
                        <h4>
                          <a>{t("core2")}</a>
                        </h4>
                        <Link to="/mobile-app-development">
                          <a className="more-btn">
                            <i className="fal fa-long-arrow-right" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="feature-middle rmt-30">
                <div className="image wow fadeInUp delay-0-2s">
                  <img
                    className="circle-text"
                    src="/assets/Curve.webp"
                    alt="Text"
                    loading="lazy"
                  />
                  <img
                    className="round"
                    src="/images/features/feature-middle.png"
                    alt="Feature Middle"
                    loading="lazy"
                  />
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="service-item style-three wow fadeInUp delay-0-3s">
                      <div className="icon">
                        <i className="flaticon-optimization" />
                      </div>
                      <div className="content">
                        <h4>
                          <a>{t("core3")}</a>
                        </h4>
                        <Link to="/software-development">
                          <a className="more-btn">
                            <i className="fal fa-long-arrow-right" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="service-item style-three mt-30 wow fadeInUp delay-0-4s">
                      <div className="icon">
                        <i className="flaticon-settings"></i>
                      </div>
                      <div className="content">
                        <h4>
                          <a>{t("core4")}</a>
                        </h4>
                        <Link to="/dev-ops">
                          <a className="more-btn">
                            <i className="fal fa-long-arrow-right" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="feature-right">
                <div className="row">
                  <div className="col-lg-12 col-sm-6">
                    <div className="service-item style-three mt-100 wow fadeInLeft delay-0-2s">
                      <div className="icon">
                        <i className="flaticon-app-development" />
                      </div>
                      <div className="content">
                        <h4>
                          <a>{t("core6")}</a>
                        </h4>
                        <Link to="/cloud-computing">
                          <a className="more-btn">
                            <i className="fal fa-long-arrow-right" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-6">
                    <div className="service-item style-three wow fadeInLeft delay-0-3s">
                      <div className="icon">
                        <i className="flaticon-user-experience" />
                      </div>
                      <div className="content">
                        <h4>
                          <a>{t("core5")}</a>
                        </h4>
                        <Link to="/digital-marketing">
                          <a className="more-btn">
                            <i className="fal fa-long-arrow-right" />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="design-feature-shapes">
          <img
            className="shape dots"
            src="/images/shapes/slider-dots.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape wave-line"
            src="/images/shapes/feature-wave-line.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      {/* Design Featured End */}
      <RecentProjects />
      {/* Working Process Area start */}
      <section
        className="work-process-area pb-95 pt-80 rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="section-title text-center mb-55 wow fadeInUp delay-0-2s">
                <span className="sub-title mb-15">{t("workingProcessH")}</span>
                <h2>{t("workingProcessSub")}</h2>
              </div>
            </div>
          </div>
          <div className="work-process-wrap rel z-1">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item mt-30 wow fadeInUp delay-0-2s">
                  <div className="icon">
                    <span className="number">01</span>
                    <i className="flaticon-optimization" />
                  </div>
                  <h4>{t("infoGatherH")}</h4>
                  <p>{t("infoGatherPara")}</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item wow fadeInUp delay-0-4s">
                  <div className="icon">
                    <span className="number">02</span>
                    <i className="flaticon-link" />
                  </div>
                  <h4>{t("PlanningH")}</h4>
                  <p>{t("PlanningPara")}</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item mt-55 wow fadeInUp delay-0-6s">
                  <div className="icon">
                    <span className="number">03</span>
                    <i className="flaticon-data" />
                  </div>
                  <h4>{t("developH")}</h4>
                  <p>{t("developPara")}</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div className="work-process-item mt-45 wow fadeInUp delay-0-8s">
                  <div className="icon">
                    <span className="number">04</span>
                    <i className="flaticon-star" />
                  </div>
                  <h4>{t("testingH")}</h4>
                  <p>{t("testingPara")}</p>
                </div>
              </div>
            </div>
            <div className="work-process-shape">
              <img
                src="/images/shapes/worp-process-step.png"
                alt="Shape"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Working Process Area end */}
      {/* CTA Area start */}
      <section className="call-to-action-area rel z-2">
        <div className="container">
          <div
            className="cta-inner bgs-cover"
            style={{
              backgroundImage: "url(/images/background/cta-bg.jpg)",
            }}
          >
            <div className="row">
              <div className="col-xl-6">
                <div className="cta-item wow fadeInLeft delay-0-2s">
                  <div className="icon d-flex justify-content-center align-items-center">
                    <GiArcheryTarget />
                  </div>
                  <h4>{t("HaveH")}</h4>
                  <Link to="/contact-us">
                    <a className="details-btn">
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="cta-item wow fadeInRight delay-0-2s">
                  <div className="icon d-flex justify-content-center align-items-center">
                    <i className="flaticon-target" />
                  </div>
                  <h4>{t("Weare")}</h4>
                  <Link to="/contact-us">
                    <a className="details-btn">
                      <i className="far fa-arrow-right" />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CTA Area end */}

      <ClientsFeedback />
      {/* Contact Area Start */}
      <section
        className="contact-area overflow-hidden py-130 bgc-black-with-lighting rel z-1"
        style={{ overflow: "hidden" }}
      >
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div className="contact-info-area text-white rmb-75 wow fadeInLeft delay-0-2s">
                <div className="section-title mb-55">
                  <h2>
                    {t("formH")}{" "}
                    <span style={{ color: "#fc653c" }}>{t("formHSpan")}</span>
                  </h2>
                </div>
                <div className="contact-info-wrap">
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fal fa-map-marker-alt" />
                    </div>
                    <div className="content">
                      <span className="title">{t("locationLabel")}</span>
                      <b className="text">{t("locationCon")}</b>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="far fa-envelope-open-text" />
                    </div>
                    <div className="content">
                      <span className="title">{t("emailLabel")}</span>
                      <b className="text">
                        <a href="mailto:info@thetechhorse.com">
                          {t("emailCon")}
                        </a>
                      </b>
                    </div>
                  </div>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="far fa-phone" />
                    </div>
                    <div className="content">
                      <span className="title">{t("phoneLabel")}</span>
                      <b className="text">
                        <a href="callto:+91 8925790019">+91 8925790019</a>
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <form
                onSubmit={handleContact}
                id="contact-area-form"
                className="contact-area-form text-center wow fadeInRight delay-0-2s"
                name="contact-area-form"
                action="#"
                method="post"
              >
                <h4>{t("formHead")}</h4>
                <input
                  type="text"
                  id="full-name"
                  name="full-name"
                  value={data.name}
                  className="form-control"
                  defaultValue=""
                  placeholder={`${t("fullNameHolder")}`}
                  required
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
                <input
                  value={data.mail}
                  type="email"
                  id="blog-email"
                  name="blog-email"
                  className="form-control"
                  defaultValue=""
                  placeholder={`${t("emailHolder")}`}
                  required
                  onChange={(e) => setData({ ...data, mail: e.target.value })}
                />
                <input
                  value={data.subject}
                  type="text"
                  id="website"
                  name="website"
                  className="form-control"
                  defaultValue=""
                  placeholder={`${t("subjectHolder")}`}
                  required
                  onChange={(e) =>
                    setData({ ...data, subject: e.target.value })
                  }
                />
                <textarea
                  value={data.message}
                  name="message"
                  id="message"
                  className="form-control"
                  rows={5}
                  placeholder={`${t("messageHolder")}`}
                  required
                  defaultValue={""}
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                />
                <button type="submit" disabled={isloader} className="theme-btn">
                  {t("sendmesBtn")} <i className="fas fa-angle-double-right" />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="contact-shapes">
          <img
            className="shape circle"
            src="/images/shapes/slider-dots.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape dots"
            src="/images/shapes/contact-dots.png"
            alt="Shape"
            loading="lazy"
          />
          <img
            className="shape wave-line"
            src="/images/shapes/contact-wave-line.png"
            alt="Shape"
            loading="lazy"
          />
        </div>
      </section>
      {/* Contact Area End */}

      {/* SuccessMessage */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center">
            <img
              className="img-fluid"
              style={{ width: "100px" }}
              src="/assets/certificate.gif"
            />
          </div>
          <h3 className="text-center mb-0">
            Your message has been sent successfully.
          </h3>
          <h3 className="text-center mt-0">We will reach you out soon...</h3>
          <div className="text-end">
            <Link to="/">
              <Button className="continue" onClick={handleClose}>Continue ..</Button>
            </Link>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CoreFeatures;
